.atbd_content_active #directorist.atbd_wrapper {
  .edit_btn_wrap{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .atbd_go_back{
        color: $primary;
      i{
        font-size: 16px;
        padding-right: 2px;
      }
    }
    .btn{
      margin-left: auto;
    }
  }
  .atbd_content_module {
    border: 1px solid $border-color;
    margin-bottom: 35px;
    background: $light;
    text-align: left;
  }
  .atbd_directory_image_thumbnail{
    margin-top: 15px;
    .single_thumbnail{
      margin: 0 10px;
      position: relative;
      cursor: pointer;
      outline: 0;
      img{
        width: 100%;
      }
      &.slick-current{
        &:before{
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(#000, 0.5);
          left: 0;
          top: 0;
          transition: $transition-base;
        }
      }
    }
  }
  .atbd_content_module__tittle_area {
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
    flex-wrap: wrap;
    label{
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        display: inline-block;
        @extend .atbd_color-primary;
      }
    }
    .atbd_listing_action_area {
      display: flex;
      span {
        margin-right: 10px;
      }
      .atbd_action {
        border: 1px solid $border-color;
        padding: 5px 10px;
        color: #7a82a6;
        cursor: pointer;
        border-radius: 4px;
        a {
          color: #7a82a6;
          &:hover {
            @extend .atbd_color-primary;
          }
        }
        &:hover {
          @extend .atbd_color-primary;
        }
        & + .atbd_action {
          margin-left: 6px;
        }
        &.atbd_share {
          position: relative;
          &:hover .atbd_director_social_wrap {
            opacity: 1;
            visibility: visible;
            top: calc(100% + 10px);
          }
          .atbd_director_social_wrap {
            ul li {
              a {
                padding: 11px 20px;
                display: block;
                > span {
                  @extend .atbd_color-primary;
                }
              }
              & + li {
                border-top: 1px solid $border-color;
              }
            }
            position: absolute;
            transition: $transition-base;
            z-index: 2;
            visibility: hidden;
            opacity: 0;
            left: 0;
            top: calc(100% + 30px);
            background: $light;
            border-radius: 4px;
            width: 180px;
            -webkit-box-shadow: $box-shadow;
            -moz-box-shadow: $box-shadow;
            box-shadow: $box-shadow;
            &:before{
              position: absolute;
              content: '';
              height: 9px;
              width: 100%;
              top: -9px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .atbdb_content_module_contents {
    padding: 30px;
  }
  .atbd_custom_fields_contents{
    .atbdb_content_module_contents {
      padding: 0;
    }
  }
  .atbd_give_review_area {
    .atbd_review_rating_area {
      display: inline-block;
      border: 1px solid $border-color;
      padding: 10px 20px;
      border-radius: 2px;
      margin-bottom: 20px;
      .atbd_review_current_rating {
        margin-bottom: 10px;
      }
      .atbd_review_current_rating .rating_label {
        margin-right: 13px;
      }
      .atbd_rating_label, .atbd_review_current_rating .atbd_rated_stars, .atbd_rating_stars {
        display: inline-block;
        vertical-align: middle;
      }
      .atbd_rating_label {
        margin-right: 10px;
      }
    }
    #atbd_up_preview {
      margin-bottom: 10px;
      .atbd_up_prev {
        width: 80px;
        height: 80px;
        position: relative;
        display: inline-block;
        border: 4px solid #dfe5ff;
        overflow: hidden;
        & + .atbd_up_prev {
          margin-left: 10px;
        }
        img {
          max-width: 100%;
        }
        .rmrf {
          position: absolute;
          top: 0;
          right: 0;
          background: #d4d3f3;
          color: $light;
          line-height: 15px;
          width: 15px;
          font-size: 11px;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            @extend .atbd_bg-danger;
          }
        }
      }
    }
    .form-group{
      textarea.form-control{
        padding-top: 10px !important;
      }
    }
  }
  .atbd_embeded_video {
    width: 100%;
    height: 400px;
  }
  .atbd_google_map {
    height: 310px;
    width: 100%;
  }
  .atbd_contact_info {
    ul li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & + li {
        margin-top: 10px;
      }
      .atbd_info_title {
        color: $dark;
        width: 35%;
        display: inline-block;
        vertical-align: top;
        &:after {
          content: ":";
          margin-left: 3px;
        }
        span {
          line-height: 35px;
          width: 35px;
          margin-right: 10px;
          border-radius: 50%;
          background: #f1f3f8;
          font-size: 14px;
          @extend .atbd_color-primary;
          text-align: center;
          &.la{
            font-size: 17px;
          }
        }
      }
      .atbd_info {
        width: 65%;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  .atbd_contact_information_module .atbd_director_social_wrap {
    margin-top: 20px;
    a {
      display: inline-block;
      line-height: 34px;
      width: 34px;
      text-align: center;
      @extend .atbd_bg-primary;
      border-radius: 4px;
      color: $light;
      span.fa{
        line-height: inherit;
      }
      &+a{
        margin-left: 5px;
      }
    }
  }
  .atbd_directry_gallery_wrapper {
    border-bottom: 1px solid $border-color;
    .atbd_big_gallery{
      position: relative;
      img{
        width:auto;
        display: inline-block;
      }
      .slick-track{
        display: flex;
        align-items: center;
      }
      .single_image{
        display: flex;
        justify-content: center;
        align-items: center;
        &:before{
          content: none;
        }
      }
    }
    .atbd_directory_image_thumbnail{
      .slick-track{
      }
      .single_thumbnail{
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    padding-bottom: 30px;
    .prev {
      left: 10px;
    }
    .next {
      right: 10px;
    }
    .prev, .next {
      position: absolute;
      font-size: 15px;
      width: 35px;
      height: 35px;
      background: rgba(#fff, 0.7);
      border-radius: 50%;
      top: 50%;
      transition: $transition-base;
      cursor: pointer;
      border: 1px solid #e3e6ef;
      color: $dark;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: $light;
        background: $primary;
        border-color: transparent;
      }
    }
  }
  .single_image{
    img{
      float: none;
      width: 100%;
      margin: 0;
    }
  }
  .atbd_listing_detail {
    padding-top: 30px;
    .atbd_data_info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .atbd_listing_meta{
        margin-top:0;
        margin-right: 10px;
      }
      .atbd_rating_count {
        margin-right: 20px;
        p {
          line-height: 20px;
          span {
            display: block;
            font-weight: 400;
            font-size: 13px;
            color: #7a82a6;
          }
        }
      }
      .atbd_badges{
        margin-right: 15px;
      }
      .atbd_listting_category{
        .directory_cats{
          display: flex;
          >li{
            margin-right: 5px;
          }
        }
      }
      .atbd_listing_average_pricing{
        margin-right: 15px;
      }
      .atbd_listting_category .directory_tags {
        li{
        display: inline-block;
        .directory_tag, .directory_tag span a{
          color: $dark;
          &:hover{
            color: $primary;
          }
        }
        &+li{
          margin-left: 8px;
        }
      }
      }
    }
    .atbd_listing_title {
      padding-bottom: 10px;
      padding-top: 18px;
      h2 {
        font-size: 30px;
        font-weight: 500;
        display: inline;
        margin:0 15px 0 0;
      }
      .dcl_claimed{
        top:-5px;
        display: inline-block;
      }
      .atbd_sub_title {
        font-size: 16px;
        color: #7a82a6;
      }
    }
    .atbd_single_listing_tagline{
      margin-bottom: 15px;
    }
    .about_detail{
      margin-top: 10px;
      ul{
        padding: 15px 0 15px 30px;
        li{
          list-style: disc;
        }
      }
      code{
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: scroll;
      }
      ol{
        padding-top: 15px;
        li{
          list-style: decimal;
        }
      }
      p{
        margin: 0 0 15px;
      }
    }
  }

  .atbd_custom_fields {
    > li {
      border-bottom: 1px solid $border-color;
      display: flex;
      &:last-child{
        border-bottom: 0;
      }
    }
    .atbd_custom_field_title {
      width: 40%;
      p {
        font-weight: 500;
        padding: 10px 30px;
        color: $dark;
      }
    }
    .atbd_custom_field_content {
      border-left: 1px solid $border-color;
      width: 60%;
      padding: 10px 15px;
    }
  }

  #client_review_list {
    .atbd_single_review {
      padding-bottom: 26px;
      padding-top: 30px;
      border-bottom: 1px solid $border-color;
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        padding-bottom: 0;
        border-bottom: 0;
      }
      .atbd_review_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .atbd_review_avatar {
          height: 50px;
          width: 50px;
          margin-right: 12px;
          img {
            width: 100%;
            height: auto;
            border-radius: 500px;
            position: relative;
            top: 0;
            left: 0;
          }
        }
        .atbd_review_avatar, .atbd_name_time {
          display: inline-block;
          vertical-align: middle;
        }
        .atbd_name_time {
          p {
            font-size: 16px;
            color: $dark;
            text-transform: capitalize;
            font-weight: 500;
          }
          .review_time {
            font-size: 13px;
            margin-left: 0;
          }
        }
      }
      .review_content {
        padding-top: 18px;
        a {
          margin-top: 15px;
          display: inline-block;
          @extend .atbd_color-primary;
          span {
            margin-right: 7px;
          }
        }
      }
    }
  }
  .atbd_related_listings {
    .atbd_related_listing_title {
      margin-bottom: 20px;
      h3{
        font-size: 20px;
      }
    }
  }

  .atbd_field_type_color{
    height: 20px;
    width: 20px;
    border-radius: 3px;
  }
}
.atbd_content_active #directorist.atbd_wrapper .atbd_listing_info .atbd_listting_category,
.atbd_content_active #directorist.atbd_wrapper .widget.atbd_widget .atbd_categorized_listings ul li .atbd_right_content, .atbd_content_active .widget.atbd_widget .atbd_categorized_listings ul li .atbd_right_content{
  .atbd_cat{
    position: relative;
    padding-left: 25px;
    display: inline-block;
    word-break: break-all;
    &:before{
      position: absolute;
      content: "\f07c";
      font-family: "FontAwesome";
      left:0;
      top:0;
    }
  }
  .atbd_cat_popup{
    position: relative;
    display: inline-block;
    margin-left: 3px;
    color: #444752;
    font-size: 13px;
    cursor: pointer;
    .atbd_cat_popup_wrapper{
      display: block;
      position: absolute;
      width: 120px;
      visibility: hidden;
      opacity: 0;
      bottom: 25px;
      left: -5px;
      transition: 0.3s ease;
      background: #000000;
      line-break: auto;
      word-break: break-all;
      padding: 7px 10px;
      border-radius: 3px;
      span{
        display: block;
        color: #fff;
        a{
          color: rgba(#fff, 0.8);
          display: block;
          line-height: normal;
          &:last-child{
            span{
              display: none;
            }
          }
          &:hover{
            color: #fff;
          }
          span{
            display: inline-block;
          }
        }
      }
      &:before{
        position: absolute;
        content: '';
        border-top: 8px solid #000;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        bottom: -8px;
        left: 10px;
      }
    }
    &:hover .atbd_cat_popup_wrapper{
      visibility: visible;
      opacity: 1;
    }
  }
  .directory_tag{
    margin: 0;
  }
  .cate_title{
    line-height: 18px;
  }
}

#directorist.atbd_wrapper .related__carousel .slick-track{
  margin-left: 0;
  margin-right: 0;
}
.contact_listing_owner{
  padding: 30px;
  textarea.form-control{
    padding-top: 10px !important;
  }
}
.hide-contant-owner{
  margin-top: 20px;
}

#directorist.atbd_wrapper .olMap .mapHover{
  min-width: 250px;
  padding: 10px;
  & > span{
    position: absolute;
    right: 4px;
    top: 2px;
    line-height: normal;
  }
  .map_info_window{
    h3{
      font-size: 15px !important;
      margin-top: 10px !important;
    }
    address{
      font-size: 14px !important;
    }
    .miw-contents-footer{
      padding-bottom: 5px;
      display: flex;
      justify-content: space-between;
      .miwl-rating{
        display: flex;
        .atbd_rating_count{
          margin-left: 5px;
        }
      }
    }
  }
}

// author info module
#directorist.atbd_wrapper .author_info_module{
  .atbdb_content_module_contents {
    .atbd_avatar_wrapper {
      display: block;
      .atbd_review_avatar {
        height: 50px;
        width: 50px;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        img {
          border-radius: 50%;
          width: 100%;
          height: 50px;
        }
      }
      .atbd_name_time {
        display: inline-block;
        vertical-align: top;
        margin-left: 7px;
        width: calc(100% - 61px);
        .review_time{
          margin-left: 0;
        }
      }
    }
    .atbd_name_time {
      display: inline-block;
    }
    .atbd_widget_contact_info {
      padding-top: 15px;
      padding-bottom: 0;
      ul li {
        margin-bottom: 6px;
        list-style-type: none !important;
        .atbd_info {
          padding-left: 10px;
          width: calc(100% - 43px);
          display: inline-block;

        }
        span.fa {
          background: #f1f3f8;
          line-height: 28px;
          width: 28px;
          text-align: center;
          border-radius: 50%;
          color: #7a82a6;
          font-size: 12px;
          vertical-align: top;
        }
      }
    }
    .atbd_social_wrap {
      margin-bottom: 0;
      padding:12px 0 0;
      p {
        display: inline-block;
        & + p {
          margin-left: 10px;
        }
        a {
          line-height: 34px;
          width: 34px;
          text-align: center;
          background: #f1f3f8;
          display: inline-block;
          border-radius: 5px;
          transition: 0.3s;
          &:hover {
            @extend .atbd_bg-primary;
            color: $light;
          }
        }
      }
    }
    .btn{
      margin-top: 20px;
    }
  }
}