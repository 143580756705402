#directorist.atbd_wrapper{
  a{
    text-decoration: none;
    box-shadow: none;
    border: none;
    &:before,&:after{
      position: initial;
    }
  }
  span{
    word-wrap: break-word;
  }
  ul{
    padding-left: 0;
    list-style-type: none;
    li{
      list-style: none;
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 0;
    }
  }
  article{
    margin-bottom: 0;
    border: 0;
    padding-bottom: 0;
  }
  img{
    box-shadow: 0 0 0;
  }
  .btn{
    position: relative;
    width: auto;
    height: auto;
  }
  .dropdown-toggle:after{
    font-size: inherit;
  }
  input[type="radio"],
  input[type="checkbox"]{
    &:checked:before{
      margin-left: -15px;
    }
  }
}
input[type="radio"],
input[type="checkbox"]{
  height: auto;
}