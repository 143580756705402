.atbd_content_active #directorist.atbd_wrapper {
  .atbd_author_module .atbd_content_module p{
    margin-bottom: 17px;
  }
  .atbd_auhor_profile_area {
    background: $light;
    border: 1px solid $border-color;
    padding: 35px 20px 35px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0 35px 0;
    .atbd_author_avatar {
      flex: 2;
      display: flex;
      align-items: center;
      padding: 0 15px;
      img{
        max-width: 120px;
        max-height: 100%;
        border-radius: 50%;
        &.avatar-32{
          width: 120px;
          height: auto;
        }
      }
      .atbd_auth_nd{
        margin-left: 20px;
        h2{
          font-size: 26px;
        }
      }
    }
    .atbd_author_meta {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 15px;
      .atbd_listing_meta{
        margin-right: 7px;
      }
      .meta-info {
        font-size: 14px;
        line-height: 22px;
        margin-left: 12px;
        position: relative;
        span {
          font-weight: 500;
          display: block;
          color: $dark;
          font-size: 16px;
        }
        &+.meta-info {
          padding-left: 20px;
          &:before {
            content: "";
            position: absolute;
            left: 5px;
            height: 100%;
            width: 1px;
            background-color: $border-color;
          }
        }
      }
    }
  }
  .atbd_author_listings_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .atbd_authors_listing{
    margin-top: 30px;
  }
}