.atbd_content_active #directorist.atbd_wrapper {
  p {
    margin: 0;
    padding: 0;
  }
  .atbd_badge {
    color: $light;
    font-size: 11px;
    padding: 0 7px;
    border-radius: 2px;
    font-weight: 500;
    display: inline-block;
    line-height: 22px;
    margin-right: 6px;
    & + .atbd_badge {
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    &.atbd_badge_open {
      @extend .atbd_bg-success;
    }
    &.atbd_badge_featured {
      @extend .atbd_bg-badge-feature;
    }
    &.atbd_badge_popular {
      @extend .atbd_bg-badge-popular;
    }
    &.atbd_badge_close{
      @extend .atbd_bg-danger;
    }
    &.atbd_badge_new{
      background: #122069;
      margin-left: 0;
    }
  }
  .atbd_listing_meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    .atbd_meta {
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: $light;
      display: inline-block;
      border-radius: 3px;
      margin-right: 6px;
      margin-top: 3px;
      margin-bottom: 3px;
      &:last-child{
        margin-right: 0;
      }
    }
    .atbd_listing_rating {
      display: flex;
      align-items: center;
      @extend .atbd_bg-primary;
      i.fa, i.la {
        font-size: 12px;
        margin-left: 5px;
      }
    }
    .atbd_listing_price {
      @extend .atbd_bg-primary;
    }
    .atbd_listing_average_pricing , .atbd_close_now{
      border: 1px solid #e3e6ef;
      > span {
        font-weight: 500;
        color: rgba(#7a82a6, .3);
        &.atbd_active {
          color: #5d658c;
        }
      }
    }
    .atbd_close_now{
      color: #f51957;
    }
  }
  .atbd_listting_category{
    a{
      word-break: break-word;
      span{
        font-size: 14px;
        display: inline-block;
        margin-right: 7px;
        line-height: 28px;
        text-align: center;
        color: #444752;
        &.la{
          font-size: 15px;
        }
      }
    }
  }
  .atbd_single_listing {
    margin-bottom: 30px;
    background: $light;
    border-radius: 4px;
    border: 1px solid $border-color;
    &.atbd_listing_list{
      .atbd_single_listing_wrapper{
        display: flex;
        .atbd_listing_thumbnail_area{
          flex: 1;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .atbd_listing_info{
          flex: 2;
          display: flex;
          flex-direction: column;
          align-content: space-between;
          justify-content: space-between;
        }
      }
    }
    .atbd_listing_thumbnail_area {
      margin: 0;
      position: relative;
      .atbd_upper_badge{
        position: absolute;
        top: 15px;
        left: 15px;
      }
      .atbd_lower_badge{
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
    }
    .atbd_listing_info {
      .atbd_content_upper {
        padding: 20px 20px 15px;
        .atbd_excerpt_content{
          margin-top: 15px;
          a{
            font-size: 13px;
            font-weight: 500;
            color: $primary;
          }
        }
        .atbd_listing_title {
          padding-top: 0;
          a{font-size: 18px;}
          text-transform: none;
          line-height: 24px;
          margin-bottom: 0;
        }
        .atbd_listing_tagline {
          color: #7a82a6;
          line-height: 26px;
          font-size: 15px;
          margin-bottom: 7px;
        }
        .atbd_listing_data_list {
          margin-top: 13px;
          ul li {
            &:last-child{
              p{
                margin-bottom: 0;
              }
            }
            p {
              margin-bottom: 7px;
              font-size: 14px;
              span {
                @extend .atbd_color-primary;
                font-size: 15px;
                margin-right: 8px;
                &.la{
                  font-size: 17px;
                }
              }
              a{
                font-size: 14px;
              }
            }
          }
        }
      }

      .atbd_listing_bottom_content{
        border-top: 1px solid $border-color;
        padding: 12px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .atbd_content_left{
          margin-right: 12px;
        }
        .atbd_content_right{
          display: flex;
          align-items: center;
          li+li{
            margin-left: 6px;
          }
          .atbd_count{
            span{
                margin-right: 5px;
            }
          }
          .atbd_save{
            span{
              color: #b2b7d0;
              line-height: 28px;
              width: 28px;
              font-size: 14px;
              border-radius: 50%;
              text-align: center;
              border: 1px solid $border-color;
            }
          }
          .atbd_author{
            a{
              width: 28px;
              height: 28px;
              display: inline-block;
              vertical-align: middle;
              img{
                border-radius: 50%;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    &.atbd_listing_card{
      .atbd_listing_image{
        position: relative;
        img{
          width: 100%;
          margin: 0;
          &.avatar{
            border-radius: 50%;
          }
        }
        .atbd_author{
          position: absolute;
          right: 15px;
          bottom:0;
          transform: translateY(50%);
          width: 46px;
          height: 46px;
          a{
            border: 3px solid #fff;
            display: block;
            border-radius: 300px;
          }
          img{
            height: auto;
            -webkit-border-radius: 300px;
            -moz-border-radius: 300px;
            border-radius: 300px;
          }
          .tooltip{
            min-width: 100px;
          }
        }
      }
    }
  }

  .select2-selection__clear{
    padding-right: 10px;
  }
  .select2-selection--multiple .select2-selection__clear{
    padding-right: 0;
    margin: 5px 0 0;
  }
  .atbd_listing_action_btn{
    display: flex;
    .dropdown{
      display: inline-block;
      .dropdown-toggle .caret{
        display: none;
      }
    }
    .dropdown+.dropdown{
      margin-left: 10px;
    }
  }
}

#directorist.atbd_wrapper span.la{
  font-size: 17px;
}

#directorist.atbd_wrapper .atbd_single_line_card_info{
  .atbd_listing_info{
    .atbd_content_upper{
      .atbd_listing_title,
      .atbd_listing_tagline,
      .atbd_listing_data_list ul li p,
      .atbd_excerpt_content{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.atbd_google_map{
  height: 250px;
}

.atbdp-map{
  .gm-style .gm-style-iw{
    width: 250px !important;
    img{
      width: auto;
      max-height: 150px;
    }
  }
}

/*style the box*/
.atbdp-map .gm-style .gm-style-iw {
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  width: 250px !important;
  //height: 100% !important;
  min-height: auto !important;
  max-height: none !important;
  padding: 18px 0 0 18px !important;
  display: block !important;
  border-radius: 3px !important;
  .gm-style-iw-d{
    height: 100% !important;
    max-height: 100% !important;
    overflow-y: scroll;
    .media-left, .media-right{
      display: block !important;
      padding-right: 0;
    }
    .media-left{
      text-align: center;
      margin-bottom: 10px;
    }
    .media-body{
      .atbdp-listings-title-block{
        margin-top: 0;
        h3{
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
        }
      }
      a.map-info-link{
        font-size: 15px;
        font-weight: 300;
      }
      .map_addr{
        margin-bottom: 5px;
      }
    }
    img{
      width: 100%;
      max-height: 150px;
    }
    .miw-contents{
      padding: 10px 10px 10px 0;
      h3{
        font-size: 15px !important;
        line-height: 18px !important;
        font-weight: 500 !important;
      }
      address{
        margin-bottom: 7px !important;
      }
      .miwl-rating{
        display: flex;
        align-items: center;
        .atbd_meta{
          margin-right: 5px;
          background: $primary;
          color: #fff;
          padding: 3px 7px;
          border-radius: 3px;
          i{
            margin-left: 3px;
          }
        }
      }
      .miw-contents-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        a{
          font-size: 14px !important;
          font-weight: 500;
        }
      }
    }
  }
}

/* ATBD Tooltip */
.atbd_tooltip.atbd_tooltip_active{
  position: relative;
  &[aria-label]:before, &[aria-label]:after{
    position: absolute !important;
    bottom: 100%;
    display: none;
    -webkit-animation: showTooltip 0.3s ease;
    -o-animation: showTooltip 0.3s ease;
    animation: showTooltip 0.3s ease;
  }
  &[aria-label]:before{
    content: "";
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid transparent;
    border-top-color: rgba(0, 0, 0, 1);
  }
  &[aria-label]:after{
    content: attr(aria-label);
    left: 50%;
    transform: translate(-50%, -12px);
    white-space: nowrap;
    background: rgba(0, 0, 0, 1);
    padding: 4px 12px;
    border-radius: 0.2em;
    color: #fff;
    z-index: 9999;
  }
  &[aria-label]:hover:before, &[aria-label]:hover:after{
    display: block;
  }
  &.atbd_tooltip--fw{
    &[aria-label]:after{
      white-space: normal;
      min-width: 120px;
      max-width: 200px;
      text-align: center;
    }
  }
}
@keyframes showTooltip {
  from {
    opacity: 0;
  }
}
