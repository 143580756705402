.atbd_content_active #directorist.atbd_wrapper{
  .select2-selection{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdaf7;
    border-radius: 0.25rem;
    min-height: 44px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &.directory_search_area{
    .select2-selection{
      height: 49px;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
      line-height: 47px;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 41px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 35px;
    right: 10px;
  }
  .select2-container--default .select2-selection--single .select2-selection__placeholder{
    color: $text-color;
  }
  .atbd_search_title_area{
    text-align: center;
    margin-bottom: 30px;
    h2.title{
      font-size: 28px;
    }
    p.sub_title{
      font-size: 18px;
      text-align: center;
    }
  }
  .single_search_field{
    .search_fields{
      line-height: 35px;
      height: 43px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
      height: 48px;
    }
  }
  .atbd_submit_btn_wrapper{
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .btn_search, .more-filter{
      padding: 13px 20px;
      margin: 5px;
      span{
        margin-right: 7px;
      }
    }
    .more-filter{
      background: #fff;
      border: 1px solid #686e77;
      &:hover{
        color: inherit !important;
        background: #fff;
      }
    }
  }
  .atbd_seach_fields_wrapper{
    padding: 30px;
    border: 1px solid $border-color;
    .atbdp-search-form{
      justify-content: center;
    }
  }
  .directory_home_category_area{
    text-align: center;
    margin-top: 60px;
    >p{
      margin-bottom: 25px;
      font-size: 18px;
      text-align: center;
    }
    .categories{
       li{
         display: inline-block;
         border: 1px solid $border-color;
         text-align: center;
         border-radius: 3px;
         padding: 10px 20px;
         margin-bottom: 15px;
         margin-left: 7px;
         margin-right: 7px;
         a p{
           text-align: center;
         }
       }
    }
  }
}
/*.select2-container{
  width: 100% !important;
  .select2-results__option{
    .first_child{
      padding-left: 10px;
    }
    .second_child{
      padding-left: 20px;
    }
    .third_child{
      padding-left: 30px;
    }
    .forth_child{
      padding-left: 40px;
    }
  }
}*/


/* ads advance search */
.ads-advaced--wrapper{
  .ads_float{
    position: relative;
    .ads-advanced{
      border-radius: 3px;
      z-index: 10000;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      box-shadow: none;
      visibility: hidden;
    }
  }
}
.ads_float{
  .ads-advanced{
    overflow-y: scroll;
  }
}

.directory_search_area{
  .ads-advanced{
    padding: 20px 30px 30px;
  }
}
.ads_slide{
  .ads-advanced{
    display: none;
  }
}
.ads-advanced{
  border: 1px solid #e3e6ef;
  margin-top: 25px;
  background: #fff;
  &.ads_ov{
    display: block;
  }
  form{
    padding: 20px 30px 30px;
  }
  .form-group{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #eef0f6;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
    transition: 0.3s ease;
    &>label{
      margin:0 !important;
      display: block;
      width: 150px;
    }
    label{
      font-weight: 500;
      &:before, &:after{
        content: none !important;
      }
    }
    .form-control{
      height: 44px;
    }
  }
  .form-control{
    box-shadow: none;
  }
  .custom-control{
    label{
      font-weight: normal;
      position: relative;
      z-index: 1;
    }
  }
  .price_ranges{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    margin: 0 -5px -10px;
    width:0;
    .range_single{
      padding: 0 5px;
      width: 145px;
      margin-bottom: 10px;
      input{
        height: 44px;
      }
    }
  }
  .price-frequency{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 7px;
    margin-bottom: 10px;
    width: auto;
    .pf-btn{
      margin-right: -1px !important;
      overflow: hidden;
      margin-bottom: 0 !important;
      &:first-child{
        border-radius: 3px 0 0 3px;
      }
      &:last-child{
        border-radius: 0 3px 3px 0;
      }
      span{
        border: 1px solid #e3e6ef;
        display: block;
        padding: 9px 15px;
        transition: .3s ease;
        cursor: pointer;
      }
      input{
        display: none;
        &:checked{
          &+span{
            background: #444752;
            color: #fff;
            border-color: #444752;
          }
        }
      }
    }
  }
  .select-basic{
    width: 250px !important;
  }
  .ads-filter-tags{
    align-items: flex-start;
  }
  .bads-tags, .bads-custom-checks{
    display: flex;
    flex-wrap: wrap;
    flex: 4;
    margin: 0 -5px;
    .custom-control{
      margin: 0 5px;
      width: 140px;
    }
    .fields_hidden{
      display: none;
    }
    label{
      line-height: normal;
    }
  }
  .atbdp_custom_radios{
    display: flex;
    flex-wrap: wrap;
    flex: 4;
  }
  .more-less, .more-or-less{
    flex: 1;
    text-align: right;
    color: #272b41;
    &:after{
      content: '\f106';
      font-family: "FontAwesome";
      padding-left: 5px;
    }
    &:focus{
      outline: 0;
    }
    &.ad{
      &:after{
        content: '\f107';
      }
    }
  }
  .wp-picker-container{
    border: 1px solid #e3e6ef;
    padding: 5px;
    border-radius: 3px;
    .button{
      height: 30px !important;
      .wp-color-result-text{
        height: 30px;
        border: 0 none;
        background: #fff;
        display: flex;
        align-items: center;
        margin: -1px -1px 0 0;
      }
    }
  }
  .bottom-inputs{
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div{
      width: 33.33%;
      padding-left: 5px;
      padding-right: 5px;
      .form-control{
        margin-bottom: 10px !important;
      }
    }
  }
  .bdas-filter-actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    .btn{
      padding: 8px 20px !important;
      margin: 0 5px !important;
    }
  }
  .atbdp-custom-fields-search{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    .form-group{
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;
      border: 0 none !important;
      padding-bottom: 0 !important;
      > div{
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        >label{
          width: 150px;
        }
      }
      &.atbdp_cf_checkbox, &.atbdp_cf_textarea, &.atbdp_cf_radio{
        width: 100%;
      }
      &.atbdp_cf_textarea{
        textarea.form-control{
          padding-top: 10px !important;
          height: 80px;
        }
      }
      &.atbdp_cf_select,
      &.atbdp_cf_date,
      &.atbdp_cf_time,
      &.atbdp_cf_color{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        label{
          flex: 1;
        }
        .form-control{
          flex: 2;
        }
      }
      &.atbdp_cf_select{
        .select-basic{
          width: auto !important;
        }
      }
      .form-control{
        width: 100% !important;
      }
      .custom-control{
        width: 140px;
      }
      > label{
        margin-bottom: 5px !important;
      }
    }
  }
  .atbdp_map_address_field{
    position: relative;
    #address_result{
      position: absolute;
      width: 96%;
      left: 2%;
      top: 45px;
      background: #fff;
      box-shadow:0 5px 10px rgba($light-gray, 0.2);
      border-radius: 3px;
      z-index: 1;
      max-height: 300px;
      overflow-y: scroll;
      ul{
        li{
          border-bottom: 1px solid $border-color;
          padding: 10px 15px 10px;
        }
      }
    }
  }
}
.atbd_header_bar .header_bar_title{
  font-size: 15px !important;
  margin-bottom: 21px !important;
}
.atbd_header_bar .ads-advanced{
  margin-top: 0;
  .atbd_seach_fields_wrapper{
    border-bottom: 1px solid #eef0f6 !important;
    margin-bottom: 15px !important;
    padding: 0 0 15px 0 !important;
  }
}