.atbd_content_active .widget.atbd_widget[id^='bd'],
.atbd_content_active .widget.atbd_widget[id^='dcl'],
.atbd_content_active #directorist.atbd_wrapper .widget.atbd_widget {
  a{
    text-decoration: none;
  }
  padding: 0;
  background: $light;
  border: 1px solid $border-color;
  margin-bottom: 30px;
  //font-family: $atbd-font-family;
  >ul, >form{
    padding: 20px;
  }
  ul li {
    border: none;
    padding: 0;
    text-align: left;
    &:before {
      display: none;
    }
  }
  &.widget_wcpcsu_widget .atw_widget-style1{
    border: 0 none;
    padding-top: 0;
    padding-bottom: 0;
    figure{
      margin: 0 10px 0 0;
    }
  }
  .atbdp-search {
    input.form-control{
      padding: 19px 15px;
    }
    .form-control{
      box-shadow: none;
    }
    .submit_btn {
      margin-top: 20px;
      margin-bottom: 0;
      .btn-primary {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        padding: 0.375rem 0.75rem;
        font-size: 15px;
        line-height: 27px;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: #fff;
        @extend .atbd_bg-primary;
        border: 0 none;
        cursor: pointer;
      }
    }
  }
  .atbd_widget_title {
    padding: 15px 30px;
    border-bottom: 1px solid $border-color;
    .atbd_widget_title {
      padding: 0;
      border: 0;
    }
    h4 {
      margin: 0 !important;
      font-size: 18px;
      font-weight: 400 !important;
      display: flex;
      flex-wrap: wrap;
      .atbd_badge{
        margin-left: auto;
        margin-right: 0;
      }
    }
    .widget-title{
      margin:0;
      display: flex;
      flex-wrap: wrap;
      .atbd_badge{
        margin-left: auto;
      }
    }
  }
  .directorist {
    text-align: center;
    .btn{
      color: #fff;
      background-color: #444752;
      border-color: #444752;
      text-decoration: none;
      padding: 8px 15px;
    }
  }
  .atbdp, .directorist {
    padding: 20px 30px;
    &.atbdp-widget-tags {
      margin-bottom: -12px;
      ul li {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        &:before {
          display: none;
        }
        a {
          border: 1px solid $border-color;
          color: #5d658c;
          border-radius: 3px;
          padding: 3px 10px;
          display: inline-block;
          font-size: 14px;
          transition: 0.3s;
          text-decoration: none;
          &:hover {
            @extend .atbd_bg-primary;
            color: $light;
          }
        }
      }
    }
  }
  .atbd_categorized_listings {
    padding: 0px 30px;
    .listings {
      padding:0;
      > li {
        padding: 15px 0;
        list-style-type: none !important;
        &:first-child{
          padding-top: 25px;
        }
        &:last-child{
          padding-bottom: 25px;
        }
        &:before {
          display: none;
        }
        & + li {
          border-top: 1px solid $border-color;
        }
        .atbd_left_img {
          max-height: 70px;
          max-width: 70px;
          display: inline-block;
          vertical-align: top;
          img {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;

            border-radius: 3px;
          }
        }
        .atbd_right_content {
          width: calc(100% - 75px);
          display: inline-block;
          vertical-align: top;
          .cate_title h4 {
            font-weight: 400;
            font-size: 16px;
            //font-family: $atbd-font-family;
            margin-bottom: 0;
          }
          .atbd_rated_stars{
            ul li{
              list-style: none !important;
            }
          }
        }
        .atbd_left_img + .atbd_right_content {
          padding-left: 10px;
        }
      }
    }
  }
  .atbdp-widget-categories {
    ul li{
      list-style-type: none !important;
    }
    .atbdp_child_category, .atbdp_child_location {
      padding-left: 40px;
      li{
        a{
          position: relative;
          &:before{
            position: absolute;
            content: '-';
            left: -10px;
            top: 50%;
            font-size: 20px;
            transform: translateY(-50%);
          }
        }
      }
      .atbdp_child_category, .atbdp_child_location{
        padding-left: 5px;
      }
    }
    .atbdp_child_location{
      padding-left: 15px;
    }
    > ul.atbdp_parent_category > li,
    > ul.atbdp_parent_location > li{
      &:hover {
        > a {
          span {
            @extend .atbd_bg-primary;
            color: $light;
          }
        }
      }
      & + li {
        margin-top: 10px;
      }
      > a {
        margin-right: 7px;
        margin-bottom: 0;
        display: inline-block;
        span {
          font-size: 12px;
          display: inline-block;
          margin-right: 10px;
          line-height: 28px;
          width: 28px;
          text-align: center;
          background: #f1f3f8;
          color: #9299b8;
          border-radius: 50%;
        }

      }
      > span {
        cursor: pointer;
        font-size: 10px;
      }
    }
    form{
      .select2-container{
        width: 100% !important;
        border: 1px solid #e3e6ef;
      }
    }
  }
  #loginform {
    text-align: left;
    p {
      &.login-username, &.login-password {
        margin-bottom: 12px;
      }
      input[type='text'], input[type='password'] {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 23px;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }
  .embed-responsive-item {
    border: 0;
    margin: 0;
  }
  iframe {
    width: 100%;
  }
  .directory_open_hours {
    ul {
      li {
        border-bottom: 1px solid #e3e6ef;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
        &:before {
          display: none;
        }
        &.atbd_today, &.atbd_today .day{
          @extend .atbd_color-success;
        }
        &.atbd_closed {
          span {
            @extend .atbd_color-danger;
            margin: 0;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        > span {
          display: inline-block;
          font-weight: 500;
          color: #614b4b;
        }
        span.atbd_open_close_time {
          font-weight: 400;
        }
      }
    }
  }

  .atbd_author_info_widget {
    .atbd_avatar_wrapper {
      display: block;
      .atbd_review_avatar {
        height: 50px;
        width: 50px;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        img {
          border-radius: 50%;
          width: 100%;
          height: 50px;
        }
      }
      .atbd_name_time {
        display: inline-block;
        vertical-align: top;
        margin-left: 7px;
        width: calc(100% - 61px);
        .review_time{
          margin-left: 0;
        }
      }
    }
    .atbd_name_time {
      display: inline-block;
    }
    .atbd_widget_contact_info {
      padding-top: 15px;
      padding-bottom: 0;
      ul li {
        margin-bottom: 6px;
        list-style-type: none !important;
        .atbd_info {
          padding-left: 10px;
          width: calc(100% - 43px);
          display: inline-block;

        }
        span.fa {
          background: #f1f3f8;
          line-height: 28px;
          width: 28px;
          text-align: center;
          border-radius: 50%;
          color: #7a82a6;
          font-size: 12px;
          vertical-align: top;
        }
      }
    }
    .atbd_social_wrap {
      margin-bottom: 0;
      padding:12px 0 0;
      p {
        display: inline-block;
        & + p {
          margin-left: 10px;
        }
        a {
          line-height: 34px;
          width: 34px;
          text-align: center;
          background: #f1f3f8;
          display: inline-block;
          border-radius: 5px;
          transition: 0.3s;
          &:hover {
            @extend .atbd_bg-primary;
            color: $light;
          }
        }
      }
    }
    .btn{
      margin-top: 20px;
    }
  }
  .atbdp-widget-listing-contact{
    form{
      .form-control{
        padding: 19px 15px;
        box-shadow: none;
        width: 100%;
      }
      textarea.form-control{
        padding-top: 10px;
      }
    }
  }
  .accordion-single{
    border-bottom: 1px solid #e3e6ef;
    padding-bottom: 10px;
    margin-bottom: 10px;
    h3{
      margin: 0;
      padding-bottom: 0;
    }
    &:last-child{
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
    .ac-body{
      margin: 10px 0 0 0;
    }
  }
  .form-group{
    display: block;
    margin-bottom: 15px;
  }

  .atbd_badge {
    color: $light;
    font-size: 11px;
    padding: 0 7px;
    border-radius: 2px;
    font-weight: 500;
    display: inline-block;
    line-height: 22px !important;
    margin-right: 6px;
    & + .atbd_badge {
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    &.atbd_badge_open {
      @extend .atbd_bg-success;
    }
    &.atbd_badge_featured {
      @extend .atbd_bg-badge-feature;
    }
    &.atbd_badge_popular {
      @extend .atbd_bg-badge-popular;
    }
    &.atbd_badge_close{
      @extend .atbd_bg-danger;
    }
    &.atbd_badge_new{
      background: #122069;
      margin-left: 0;
    }
  }

}

.atbd_content_active .widget.atbd_widget{
  & + .modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;

    .modal-dialog {
      position: relative;
      max-width: 500px;
      margin: 0.5rem auto;
      pointer-events: none;
    }

    &.fade  .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -25%);
    }
    &.show  .modal-dialog {
      transform: translate(0, 0);
    }

    .modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - (0.5rem * 2));
    }

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
    }

    .modal-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
    }

    .modal-backdrop.fade {
      opacity: 0;
    }

    .modal-backdrop.show {
      opacity: 0.5;
    }

    .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    .modal-header .close {
      padding: 1rem;
      margin: -1rem -1rem -1rem auto;
    }

    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
    }

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem;
      border-top: 1px solid #e9ecef;
    }

    .modal-footer > :not(:first-child) {
      margin-left: .25rem;
    }

    .modal-footer > :not(:last-child) {
      margin-right: .25rem;
    }

    .modal-scrollbar-measure {
      position: absolute;
      top: -9999px;
      width: 50px;
      height: 50px;
      overflow: scroll;
    }
  }
  & + #dcl-claim-modal{
    label{
      margin-bottom: 10px;
      display: block;
    }
    .form-group{
      margin-bottom: 15px;
    }
    .form-control{
      width: 100%;
      max-width: 100%;
    }
    .dcl_plans{
      display: block;
      margin-top: 10px;
    }
    .modal-footer{
      flex-direction: column;
      .btn{
        background: #444752;
        color: #fff;
      }
      span{
        display: block;
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 576px) {
  .atbd_content_active .widget.atbd_widget + .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .atbd_content_active .widget.atbd_widget + .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .atbd_content_active .widget.atbd_widget + .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .atbd_content_active .widget.atbd_widget + .modal-lg {
    max-width: 800px;
  }
}