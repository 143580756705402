.atbd_content_active #directorist.atbd_wrapper {
  .select2-container--default.select2-container--focus .select2-selection--multiple
  /*.select2-container--default .select2-selection--multiple*/ {
    border-color: $border-color;
  }
  .select2-container--default .select2-selection {
    padding: 0 20px;
  }
  .atbd_add_listing_title {
    text-align: center;
    margin-top: 15px;
    h3 {
      margin: 0;
      font-size: 28px;
      padding-bottom: 27px;
    }
  }
  #wp-listing_content-wrap .mce-container {
    .mce-btn button {
      padding: 0;
      min-width: initial;
      min-height: initial;
      background-color: initial;
      border-color: inherit;
      color: initial;
    }
  }
  [data-toggle="tooltip"] {
    margin-left: 4px;
    color: #85889a;
    font-size: 12px;
    &:hover{
      color: $primary;
    }
  }
  label[data-option] {
    color: #505058;
    input{
      height: auto;
    }
  }
  #tc_container {
    height: 350px;
    border: 1px solid #ededed;
    padding: 30px;
    text-align: left;
    overflow-y: scroll;
    margin-bottom: 30px;
    background: $light;
    margin-top: 15px;
    display: none;
  }
  #tc_container.active{
    display: block;
  }
  #atbdp_address{
    margin-bottom: 15px;
  }
  #gmap {
    height: 350px;
    position: relative;
  }
  #hide_if_no_manual_cor {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 5px 0 15px;
    .lat_btn_wrap{
      margin-top: 10px;
    }
  }
  .map_wrapper {
    position: relative;
    #floating-panel {
      position: absolute;
      top: 10px;
      right: 58px;
      z-index: 1;
    }
    .map_drag_info{
      display: block;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .cor-wrap{
      label{
        margin-bottom: 0;
      }
    }
  }
  .hide-map-option{
    label{
      margin-left: 0;
    }
  }
  .atbd_plan_alert {
    a.btn {
      margin-left: 10px;
    }
    strong {
      display: inline-block;
      margin: 0 5px;
    }
  }
  .atbdp-radio-list, .atbdp-checkbox-list {
    li {
      label {
        font-size: 13px;
        margin:0;
        input {
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }
  }
  input.select2-search__field{
    margin-top: 6px;
  }
  .close {
    padding: 0;
    top: 50%;
    right: 10px;
    position: absolute;
    transform: translateY(-50%);
    background: none;
    color: #000;
    &:hover {
      background: none;
    }
  }
  &.atbd_add_listing_wrapper {
    .atbd_plan_alert {
      margin-bottom: 30px;
    }
    .hide-if-no-js {
      text-align: center !important;
    }
    .listing-img-container {
      text-align: center;
      padding-bottom: 15px;
      img {
        display: inline-block;
        height: auto;
        width: auto;
      }
      .single_attachment {
        width: 205px;
        display: inline-block;
        vertical-align: top;
        padding: 10px;
        position: relative;
        .remove_image {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          background: #d3d1ec;
          color: #fff;
          width: 20px;
          line-height: 20px;
          font-size: 13px;
          border-radius: 50%;
          &:hover {
            @extend .atbd_color-danger;
          }
        }
      }
      p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        text-align: center !important;
      }
      small {
        font-size: 13px;
      }
    }
    .default_img {
      text-align: center;
      margin-bottom: 15px;
    }
    .single_prv_attachment {
      text-align: center;
      img {
        margin-bottom: 15px;
      }
      div{
        position: relative;
        display: inline-block;
        .remove_prev_img{
          position: absolute;
          top: -5px;
          right: -5px;
          background: #d3d1ec;
          line-height: 20px;
          width: 20px;
          border-radius: 50%;
          transition: 0.2s;
          cursor: pointer;
          color: #ffffff;
          font-size: 13px;
          &:hover{
            color: $danger;
          }
        }
      }
    }
    .atbd_term_and_condition_area {
      text-align: center;
      margin-bottom: 20px;
      > label {
        display: inline-block;
        margin: 0;
      }
    }
    .btn_wrap.list_submit {
      text-align: center;
      .listing_submit_btn {
        line-height: 32px;
        padding: 9px 40px;
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
  }
  .atbd_listing_type {
    padding-bottom: 20px;
    h4 {
      margin-bottom: 15px;
    }
    .atbd_listing_type_list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      label {
        display: block;
        padding: 0 10px;
        input[type="radio"]{
          height: auto;
        }
      }
    }
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered{
  margin-top: 3px !important;
}

#directorist.atbd_wrapper .olMap + .cor-wrap{
  margin-top: 15px;
}
#directorist.atbd_wrapper .olMap .olControlAttribution{
  right: 10px !important;
  bottom: 10px !important;
}
.atbd_general_information_module{
  .not_empty{
    select{
      margin-top: 6px;
    }
  }
  .form-group{
    margin-bottom: 25px !important;
    &:nth-last-child(2){
      margin-bottom: 0 !important;
    }
    .form-control{
      box-shadow: none;
    }
    .atbd_pricing_options{
      span{
        padding: 0 15px;
      }
    }
  }
}
#directorist.atbd_wrapper .atbd_contact_information{
  .form-check{
    margin-bottom: 10px;
  }
  .form-group{
    margin-bottom: 25px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
#directorist.atbd_wrapper .atbd_location_map_setting{
  .atbdb_content_module_contents{
    & > label{
      margin-bottom: 15px;
    }
    .cor-wrap{
      margin: 20px 0 10px;
      label{
        margin-bottom: 0;
      }
    }
    .form-group{
      label{
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
}

#_listing_gallery{
  #gallery_upload{
    padding-top: 0;
    margin-top: 0;
  }
}

#directorist.atbd_wrapper #atbdp-custom-fields-list{
  margin: 13px 0 0 0;
  #custom_field_for_cat{
    ul li label{
      padding-left: 0;
      input{
        position: relative;
      }
    }
  }
}

#directorist.atbd_wrapper .atbdp_social_field_wrapper{
  margin-bottom: 15px;
}
#directorist.atbd_wrapper .atbdp_make_str_red{
  color: #f00;
  padding: 0 0 0 3px;
}
#directorist.atbd_wrapper .c_cat_list{
  strong{
    font-weight: 500;
  }
}

#directorist.atbd_wrapper{
  .wp-picker-container{
    .button{
      position: relative;
      height: 40px;
      border: 0 none;
      width: 140px;
      transition: $transition-base;
      border-radius: 3px;
      &:hover{
        background: $body-color;
      }
      .wp-color-result-text{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .wp-picker-input-wrap{
      label{
        width: 90px;
        margin: 0 10px;
        input{
          width: 100%;
          height: 40px;
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .default-ad-search{
    .wp-picker-container .wp-picker-input-wrap label{
      margin: 0;
      .search-color-field{
        border: 0 none;
        background: none;
      }
    }
  }
}
#directorist.atbd_wrapper.atbd_add_listing_wrapper  textarea#atbdp_excerpt{
  padding-top: 10px;
}