/* Common styles across whole plugin */
body.atbd_content_active #directorist.atbd_wrapper {
  line-height: 24px;
  max-width: 100%;
  direction: ltr;
  text-align: left;
}

#directorist.atbd_wrapper input[type="radio"] {
  padding: 0 0 0 10px;
}

#directorist.atbd_wrapper input[type="checkbox"] {
  padding: 0 0 0 15px;
}

#directorist.atbd_wrapper .ads-advanced input[type="checkbox"],
#directorist.atbd_wrapper .default-ad-search input[type="checkbox"],
#directorist.atbd_wrapper .ads-advanced input[type="radio"],
#directorist.atbd_wrapper .default-ad-search input[type="radio"] {
  display: none;
}

#directorist.atbd_wrapper .row {
  float: none;
}

#directorist.atbd_wrapper .dropdown-menu {
  z-index: 100000;
}

//stop preload transition
.atbdp_preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.atbd_content_active #directorist.atbd_wrapper h1, .atbd_content_active #directorist.atbd_wrapper h2, .atbd_content_active #directorist.atbd_wrapper h3, .atbd_content_active #directorist.atbd_wrapper h4, .atbd_content_active #directorist.atbd_wrapper h5, .atbd_content_active #directorist.atbd_wrapper h6,
.atbd_content_active #directorist.atbd_wrapper .h1, .atbd_content_active #directorist.atbd_wrapper .h2, .atbd_content_active #directorist.atbd_wrapper .h3, .atbd_content_active #directorist.atbd_wrapper .h4, .atbd_content_active #directorist.atbd_wrapper .h5, .atbd_content_active #directorist.atbd_wrapper .h6 {
  margin-bottom: $headings-margin-bottom;
  //font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  letter-spacing: normal;

  a {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    color: $headings-color;

    &:hover {
      @extend .atbd_color-primary;
    }
  }
}

.atbd_content_active #directorist.atbd_wrapper.size-xs .atbdp_column {
  width: 50%;
}

.atbd_content_active #directorist.atbd_wrapper .atbdp_column {
  padding-left: 15px;
  padding-right: 15px;
}

.atbd_content_active #directorist.atbd_wrapper {
  div, p, a, li {
    //font-family: 'Roboto', Sans-serif !important;
    font-size: $font-size-base;
  }

  color: $text-color;
  font-size: 15px;
  //font-family: 'Roboto', Sans-serif !important;
  text-align: initial;

  .modal-footer {
    .btn + .btn {
      margin-left: 10px;
    }
  }

  .modal-content {
    padding: 0;
  }

  p {
    font-size: $font-size-base;
    color: $text-color;
    line-height: 24px;
    text-align: left;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .atbd_header_bar {
    margin-bottom: 35px;
  }

  .atbd_generic_header {
    background: $light;
    padding: 20px 30px;
    border-radius: 5px;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .atbd_generic_header_title {
      display: flex;
      align-items: center;
      justify-content: center;

      .more-filter {
        margin-right: 15px;
      }

      h3 {
        color: #7a82a6;
        margin: 0;
        font-size: 15px;

        p {
          font-size: 14px;
        }
      }
    }

    .atbd_listing_action_btn {
      margin-left: auto;
    }
  }
}

.shadow-lg-2 {
  -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
  box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
}

.atbd_border-color {
  border-color: $border-color;
}

#directorist.atbd_wrapper {
  * {
    box-sizing: border-box;
  }

  .list-group-item {
    padding-left: 10px;
  }

  .gateway_list label input {
    margin-right: 10px;
  }

  .iris-picker, .iris-picker * {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  .dropdown {
    .dropdown-toggle span.caret {
      display: none;
    }
  }

  .fa {
    font: normal normal normal 14px/1 FontAwesome;
  }

  .atbd_rated_stars {
    ul li {
      display: inline-block;

      &:before {
        display: none;
      }

      span {
        color: #d4d3f3;

        &:before {
          content: "\f005";
          font-size: 14px;
          font-family: 'Fontawesome', Sans-serif;
        }

        &.rate_active {
          @extend .atbd_rating_color;
        }
      }
    }
  }

  .atbd_rating_stars {
    .br-widget {
      .br-current-rating {
        display: inline-block;
        margin-left: 20px;
      }

      a {
        &:before {
          content: "\f005";
          font-size: 14px;
          font-family: 'Fontawesome', Sans-serif;
          color: #d4d3f3;

          &.br-selected, &.br-active {
            @extend .atbd_rating_color;
          }
        }
      }
    }
  }

  .atbd_upload_btn_wrap label {
    display: inline-block;
  }

  label {
    color: $dark;

    label {
      display: inline-block;
      margin-bottom: $label-margin-bottom;
    }
  }

  .atbd_payment_recipt {
    background: #fff;
    padding: 30px;
    border: 1px solid #ededed;
    border-radius: 2px;
    margin-bottom: 35px;

    .atbd_thank_you {
      text-align: center;
      font-size: 24px;
      color: $dark;
      margin-bottom: 40px;
    }

    .atbd_payment_instructions {
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid $border-color;
    }

    .atbd_payment_summary_wrapper {
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;
      padding-bottom: 17px;
    }

    .atbd_payment_summary {
      text-align: center;
      font-size: 18px;
      color: $dark;
      margin-bottom: 20px;
    }
  }

  #atbdp_checkout_total_amount, .atbd_tottal {
    font-size: 20px;
    font-weight: 500;
  }

  #directorist-checkout-table {
    margin-bottom: 35px;
  }
}

.atbd_rating_color {
  color: #fa8b0c;
}

.atbd-text-center {
  text-align: center;
}

.atbd_content_active .media-frame-title h1:before {
  margin-top: 0;
  display: none;
}

//pagination css
#directorist.atbd_wrapper .pagination {
  width: 100%;
  margin: 0;

  &:before, &:after {
    content: none;
  }

  border-top: 0;

  .next {
    &:before {
      content: none;
    }

    right: auto;
  }

  text-align: center;
  box-shadow: none;

  .nav-links {
    background: none;
    margin: 0;
    padding: 0;
    justify-content: center;

    .current, .page-numbers {
      padding: 0;
      line-height: normal;
      height: 35px;
      width: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d8d8d8;
      border-radius: 300px;
      background: #fff;
      transition: 0.3s;
      color: #7a82a6;
      text-align: center;
      margin-right: 2px;
      margin-left: 0;
      right: auto;
      float: none;

      &:last-child {
        margin-right: 0;
        margin-left: 2px;
      }
    }

    .current {
      border-radius: 300px;
      background: $primary;
      box-shadow: none;
      border-color: transparent;
      color: #fff;
    }

    .page-numbers {
      span {
        font-size: 10px;
        border: 0 none;
        margin: 0;
        height: auto;
      }

      &:after {
        content: none;
      }
    }

    &:before, &:after {
      content: none;
    }
  }
}

#directorist.atbd_wrapper, .default-ad-search {
  .form-control {
    padding: 10px 15px;
    height: auto;
    &[type="time"], &[type="date"] {
      padding: 9px 15px;
    }
  }

  textarea.form-control {
    padding-top: 0;
  }
}

.atbd_content_active #directorist.atbd_wrapper {
  #atbdp-report-abuse-modal {
    .at-modal-close {
      width: 32px;
      height: 32px;
      top: 20px;
      right: 20px;
      position: absolute;
      transform: none;
      background: #444752;
      color: #fff;
      border-radius: 300px;
      opacity: 1;
      font-weight: 300;
      z-index: 2;
      font-size: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }

  .at-modal-content {
    #atbdp-report-abuse-form {
      width: 100%;
      padding: 20px;

      textarea {
        margin-top: 10px;
        padding-top: 10px;
      }
    }
    .atm-contents-inner{
      box-shadow: 0 5px 20px rgba($primary, 0.2);
    }
  }

  .modal-dialog {
    .modal-body {
      padding: 15px 0 10px;

      label {
        margin-bottom: 8px;
      }

      textarea {
        box-shadow: none;
        padding-top: 8px;
      }
    }

    .modal-header {
      padding-left: 0;

      .close {
        padding: 2px 10px;
        top: 20px;
        right: 20px;
        position: absolute;
        transform: none;
        background: $primary;
        color: #fff;
        border-radius: 300px;
        opacity: 1;
        font-weight: 300;

        span {
          margin: 0;
          position: relative;
          top: -1px;
        }
      }
    }

    .modal-footer {
      border: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}

.atbdp_login_form_shortcode {
  & > p {
    font-size: 15px;
  }

  .login-username label, .login-password label, .login-remember label {
    font-size: 15px;
    font-weight: 400;
  }

  #login {
    p {
      margin-bottom: 10px !important;

      label {
        display: block;
      }

      input {
        background: none !important;
        padding: 10px 15px;
        box-shadow: none;
        border: 1px solid #e3e6ef;
        width: 100%;

        &[type="submit"] {
          color: #fff !important;
          background: #444752 !important;
          border-color: #444752 !important;
          padding: 8px 13px !important;
          border-radius: 4px !important;
          width: auto;
          line-height: normal;
        }
      }
    }
    .atbd_login_btn_wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .status{
    span{
      padding: 7px 15px;
      display: block;
      margin-top: 10px;
      border-radius: 3px;
      &.status-info{
        background: rgba($info, 0.1);
      }
      &.status-success{
        background: rgba($success, 0.1);
      }
      &.status-warning{
        background: rgba($warning, 0.1);
      }
      &.status-failed{
        background: rgba($danger, 0.1);
      }
    }
  }

  .login-password {
    margin-top: 10px !important;
  }

  .login-remember {
    margin-top: 10px !important;

    label {
      display: inline-block;

      input {
        width: auto !important;
      }
    }
  }

  .alert-danger {
    background: #F9D7D7;
    padding: 8px 15px;
  }

  .atbdp_recovery_pass {
    margin-top: 0 !important;
    display: block;

    &:focus {
      outline: 0;
    }
  }

  #recover-pass-modal {
    border: 1px solid $border-color;
    padding: 15px;
    margin-top: 10px;

    #reset_user_login {
      width: 100%;
      margin-bottom: 10px;
    }

  }

}

#directorist.atbd_wrapper .atbd_related_listings .atbd_listing_info .atbd_content_upper {
  & > p {
    margin-top: 13px;
  }
}

#directorist.atbd_wrapper .directory_cats {
  li {
    display: inline-block;
  }
}

#directorist.atbd_wrapper .atbd_listing_pagination {
  margin-bottom: 80px;
}

#directorist.atbd_wrapper .atbd_author_filter_area .dropdown .dropdown-menu {
  width: 157px;
  word-break: break-all;
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
}

#directorist.atbd_wrapper .atbd_author_filter_area .dropdown .dropdown-menu .dropdown-item {
  white-space: pre-line;
  width: 157px;
}

#directorist.atbd_wrapper .directory_regi_btn {
  text-align: left;
}

#directorist.atbd_wrapper #listing_image_btn {
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 8px;
  }

  &:focus {
    box-shadow: none;
  }
}

#directorist.atbd_wrapper #listing_image_btn,
#directorist.atbd_wrapper #delete-custom-img {
  margin: 3px;
}

.atbdp_required {
  display: block;
  margin-top: 10px;
  color: $danger;
}

#directorist.atbd_wrapper .atbdp_custom_field_radio {
  li label {
    display: flex;
  }
}

.atbdp-map {
  margin-bottom: 35px;
}

.atbd_widget .atbdp-map {
  margin-bottom: 0;
}

#recover-pass-modal {
  .modal-body {
    padding: 30px;

    label {
      display: block;
      font-weight: 600;
      margin-top: 15px;
      color: $text-color;
    }

    input[type="text"] {
      width: 100%;
      border: 1px solid $border-color;
      height: 45px;
      padding: 0 15px;
      margin-bottom: 15px;
    }
  }
}

#directorist.atbd_wrapper .form-check-label {
  margin-left: 10px;
}

.atbd_margin_fix {
  margin-left: -10px;
  margin-right: -10px;
}

#directorist.atbd_wrapper .avatar {
  position: relative;
  left: 0;
  top: 0;
  float: none;
}

.atbd_content_active #directorist.atbd_wrapper #map.leaflet-container {
  margin-bottom: 30px;

  .leaflet-popup-content-wrapper {
    border-radius: 3px;

    .leaflet-popup-content {
      margin: 15px;
      width: 220px !important;
    }
  }
}

#directorist.atbd_wrapper .directory_regi_btn {
  margin-top: 10px;

  a {
    color: $danger;
  }
}

#directorist.atbd_wrapper .fade.show {
  opacity: 1;
}

.wp-switch-editor {
  top: 0 !important;
  height: 35px !important;
}

//file upload
.plupload-thumbs {
  .thumb {
    position: relative;

    &:hover {
      .atbdp-thumb-actions {
        display: flex !important;
      }
    }

    .atbdp-thumb-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin-top: 0;
      align-items: center;
      justify-content: center;

      .thumbremovelink {
        background: #000;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: .3s ease;

        &:hover {
          background: #e23636;
        }
      }
    }
  }
}

.plupload-upload-uic {
  width: 420px;
  margin: 0 auto !important;
  border: 1px dashed #dbdee9;
  padding: 30px;

  .atbdp-dropbox-title {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 15px;
  }

  .atbdp_button {
    border: 1px solid $border-color;
    background: #f8f9fb;
    font-size: 14px;
    box-shadow: none;
    line-height: 40px !important;
    padding: 0 30px !important;
    height: auto !important;
    transition: 0.3s ease;
    color: inherit;
  }

  .atbdp-dropbox-file-types {
    margin-top: 10px;
    color: #9299b8;
  }
}

@media (max-width: 575px) {
  .plupload-upload-uic {
    width: 100%;
  }
}

.tabContentActive {
  display: block !important;
  animation: showTab 0.6s ease;
}

.atbd_tab_inner {
  display: none;
}

@keyframes showTab {
  from {
    opacity: 0;
  }
}

.atbd_content_active #directorist.atbd_wrapper.directory_search_area .single_search_field .select2-selection {
  height: 40px;

  .select2-selection__rendered {
    line-height: 42px !important;
  }

  .select2-selection__arrow {
    height: 42px !important;
  }
}

#directorist.atbd_wrapper #atbdp-checkout-form #atbdp_checkout_submit_btn {
  background: #444752 !important;
}

#OpenLayers_Map_2_OpenLayers_Container {
  pointer-events: none;
}

.plupload-thumbs .thumb{
  height: auto !important;
  float: none !important;
}
#directorist.atbd_wrapper .containess-fluid .atbdp-related-listing-header h4{
  margin-bottom: 20px;
}